
(function($) {
	"use strict";

	$('#sidebarCollapse').on('click', function () {
      $('#sidebar').toggleClass('active');
		$('#sidecontrol').toggleClass('hide');
		$('#wrapper').toggleClass('block');
  });
	$('#sidebarCollapseOpen').on('click', function () {
      $('#sidebar').toggleClass('active');
		$('#sidecontrol').toggleClass('hide');
		$('#wrapper').toggleClass('block');
  });


})(jQuery);
$(document).ready(function() {
  $('.nav-link-collapse').on('click', function() {
    $('.nav-link-collapse').not(this).removeClass('nav-link-show');
    $(this).toggleClass('nav-link-show');
  });
});